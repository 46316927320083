/**
 * @file
 *
 * This file contains the data fetching code for bookmarks and logs of a connection
 */
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { produce } from 'immer';

import { dataflowApiBase, getServiceInstance } from '../service';
import { useTenantState } from './user';
import { useNotifyError } from '../hooks/useNotifyError';
import { BOOKMARK_TYPE } from 'components/ReportViewComponents/constants';

const queryFunction = ({ queryKey }) => {
  const [endpoint, tenantId] = queryKey;

  return getServiceInstance(tenantId).get(`${dataflowApiBase}${endpoint}`);
};

/**
 * custom data fetch hook to get the logs for the  selected conenction details
 *
 * @param {string} connectionId
 */
export function useQueryLogs(connectionId) {
  const tenant = useTenantState();

  const { data, error, isLoading, isFetching, refetch } = useQuery({
    queryKey: [`/connection/${connectionId}/log`, tenant?.tenant_id],
    queryFn: queryFunction,
    enabled: Boolean(tenant),
  });

  return {
    error,
    isLoading,
    isFetching,
    data,
    refetch,
  };
}

/**
 * custom data fetch hook to get the bookmark for the  selected conenction details
 *
 * @param {string} connectionId
 */
export function useQueryBookmarks(connectionId, view) {
  const tenant = useTenantState();

  const { data, error, isLoading, isFetching, refetch } = useQuery({
    queryKey:
      view === BOOKMARK_TYPE.REPORT_VIEW
        ? [`/connection/${connectionId}/collection?view=${view}`, tenant?.tenant_id]
        : [`/connection/${connectionId}/bookmark`, tenant?.tenant_id],
    queryFn: queryFunction,
    enabled: Boolean(tenant),
    refetchOnMount: tenant && connectionId ? 'always' : false,
  });

  return {
    error,
    isLoading,
    isFetching,
    data,
    refetch,
  };
}

/**
 * custom data fetch hook to get the user groups for the selected tenant
 *
 */
export function useQueryAccessGroups() {
  const tenant = useTenantState();

  const { data, error, isLoading, isFetching, refetch } = useQuery({
    queryKey: [`/access-group`, tenant?.tenant_id],
    queryFn: queryFunction,
    enabled: Boolean(tenant),
    refetchOnMount: tenant ? 'always' : false,
  });

  return {
    error,
    isLoading,
    isFetching,
    data,
    refetch,
  };
}

/**
 * custom data fetch hook to get the predefined bookmarks
 *
 * @param {string} connectionId
 */
export function usePredefinedBookmarks(connectionId) {
  const tenant = useTenantState();

  const { data, error, isLoading, isFetching, refetch } = useQuery({
    queryKey: [`/connection/${connectionId}/suggestions`, tenant?.tenant_id],
    queryFn: queryFunction,
    enabled: Boolean(tenant),
  });

  return {
    error,
    isLoading,
    isFetching,
    data,
    refetch,
  };
}

const createCollection = (collectionInfo, tenant, connectionId) =>
  getServiceInstance(tenant?.tenant_id).post(
    `${dataflowApiBase}/connection/${connectionId}/collection`,
    collectionInfo
  );
export const useCreateCollectionMutations = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ collectionInfo, tenant, connectionId }) =>
      createCollection(collectionInfo, tenant, connectionId),
    {
      onSuccess: (data, { connectionId, tenant, collectionInfo }) => {
        const collections = queryClient.getQueryData([
          `/connection/${connectionId}/collection?view=${collectionInfo.view}`,
          tenant?.tenant_id,
        ]);

        queryClient.setQueryData(
          [`/connection/${connectionId}/collection?view=${collectionInfo.view}`, tenant?.tenant_id],
          [data, ...collections]
        );
      },
      onSettled: (data, error, { connectionId, tenant, collectionInfo }) => {
        queryClient.invalidateQueries([
          `/connection/${connectionId}/collection?view=${collectionInfo.view}`,
          tenant?.tenant_id,
        ]);
        queryClient.invalidateQueries([`/tenant/${tenant.tenant_id}/usage`, tenant.tenant_id]);
      },
    }
  );

  useNotifyError({
    error: mutation.error,
    fallbackMessage: 'Error: Failed to create a collection',
  });

  return {
    createCollectionMutation: mutation,
  };
};

const deleteCollection = (tenant, connectionId, collectionId) =>
  getServiceInstance(tenant.tenant_id).delete(
    `${dataflowApiBase}/connection/${connectionId}/collection/${collectionId}`
  );
export const useDeleteCollectionMutations = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ tenant, connectionId, collectionId }) =>
      deleteCollection(tenant, connectionId, collectionId),
    {
      onMutate: async ({ tenant, connectionId, collectionId, view }) => {
        await queryClient.cancelQueries([
          `/connection/${connectionId}/collection?view=${view}`,
          tenant?.tenant_id,
        ]);

        const collections = queryClient.getQueryData([
          `/connection/${connectionId}/collection?view=${view}`,
          tenant?.tenant_id,
        ]);

        queryClient.setQueryData(
          [`/connection/${connectionId}/collection?view=${view}`, tenant?.tenant_id],
          collections.filter((collection) => collection.collection_id !== collectionId)
        );

        return { collections };
      },
      onError: (error, { tenant, connectionId, view }, context) => {
        queryClient.setQueryData(
          [`/connection/${connectionId}/collection?view=${view}`, tenant?.tenant_id],
          context.collections
        );
      },
      onSettled: (data, error, { connectionId, tenant, view }) => {
        queryClient.invalidateQueries([
          `/connection/${connectionId}/collection?view=${view}`,
          tenant?.tenant_id,
        ]);
        queryClient.invalidateQueries([`/tenant/${tenant.tenant_id}/usage`, tenant.tenant_id]);
      },
    }
  );

  useNotifyError({
    error: mutation.error,
    fallbackMessage: 'Error: Failed to delete collection',
  });

  return { deleteCollectionMutation: mutation };
};

const editCollection = (collectionInfo, tenant, selectedCollection) =>
  getServiceInstance(tenant?.tenant_id).patch(
    `${dataflowApiBase}/connection/${selectedCollection.connection_id}/collection/${selectedCollection.id}`,
    collectionInfo
  );

export const useEditCollectionMutaton = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ collectionInfo, tenant, selectedCollection }) =>
      editCollection(collectionInfo, tenant, selectedCollection),
    {
      onSuccess: (data, { selectedCollection, tenant, collectionInfo }) => {
        const collections = queryClient.getQueryData([
          `/connection/${selectedCollection.connection_id}/collection?view=${collectionInfo.view}`,
          tenant?.tenant_id,
        ]);

        const updatedCollectionIndex = collections.findIndex(
          (collection) => collection.id === selectedCollection.id
        );

        const updatedCollections = produce(collections, (draft) => {
          draft[updatedCollectionIndex].name = collectionInfo.name;
          draft[updatedCollectionIndex].description = collectionInfo.description;
        });

        queryClient.setQueryData(
          [
            `/connection/${selectedCollection.connection_id}/collection?view=${collectionInfo.view}`,
            tenant?.tenant_id,
          ],
          updatedCollections
        );
      },
      onSettled: (data, error, { tenant, selectedCollection, collectionInfo }) => {
        queryClient.invalidateQueries([
          `/connection/${selectedCollection.connection_id}/collection?view=${collectionInfo.view}`,
          tenant?.tenant_id,
        ]);
      },
    }
  );

  useNotifyError({
    error: mutation.error,
    fallbackMessage: 'Error: Failed to update the collection',
  });

  return { editCollectionMutation: mutation };
};

const deleteBookmark = (tenant, connectionId, bookmarkId) =>
  getServiceInstance(tenant.tenant_id).delete(
    `${dataflowApiBase}/connection/${connectionId}/bookmark/${bookmarkId}`
  );
export const useDeleteBookmarkMutations = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ tenant, connectionId, bookmarkId }) => deleteBookmark(tenant, connectionId, bookmarkId),
    {
      onMutate: async ({ tenant, connectionId, bookmarkId }) => {
        await queryClient.cancelQueries([
          `/connection/${connectionId}/bookmark`,
          tenant?.tenant_id,
        ]);

        const bookmarks = queryClient.getQueryData([
          `/connection/${connectionId}/bookmark`,
          tenant?.tenant_id,
        ]);

        queryClient.setQueryData(
          [`/connection/${connectionId}/bookmark`, tenant?.tenant_id],
          bookmarks.filter((bookmark) => bookmark.query_bookmark_id !== bookmarkId)
        );

        return { bookmarks };
      },
      onError: (error, { tenant, connectionId }, context) => {
        queryClient.setQueryData(
          [`/connection/${connectionId}/bookmark`, tenant?.tenant_id],
          context.bookmarks
        );
      },
      onSettled: (data, error, { connectionId, tenant }) => {
        queryClient.invalidateQueries([`/connection/${connectionId}/bookmark`, tenant?.tenant_id]);
        queryClient.invalidateQueries([
          `/connection/${connectionId}/collection?view=REPORT_VIEW`,
          tenant?.tenant_id,
        ]);
        queryClient.invalidateQueries([`/tenant/${tenant.tenant_id}/usage`, tenant.tenant_id]);
      },
    }
  );

  useNotifyError({
    error: mutation.error,
    fallbackMessage: 'Error: Failed to delete bookmark',
  });

  return { deleteBookmarkMutation: mutation };
};

const createBookmark = (formData, tenant, connectionId) =>
  getServiceInstance(tenant?.tenant_id).post(
    `${dataflowApiBase}/connection/${connectionId}/bookmark`,
    formData
  );
export const useCreateBookmarkMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ formData, tenant, connectionId }) => createBookmark(formData, tenant, connectionId),
    {
      onSuccess: (data, { connectionId, tenant }) => {
        const bookmarks = queryClient.getQueryData([
          `/connection/${connectionId}/bookmark`,
          tenant?.tenant_id,
        ]);

        queryClient.setQueryData(
          [`/connection/${connectionId}/bookmark`, tenant?.tenant_id],
          [data, ...bookmarks]
        );
      },
      onSettled: (data, error, { connectionId, tenant }) => {
        queryClient.invalidateQueries([`/connection/${connectionId}/bookmark`, tenant?.tenant_id]);
        queryClient.invalidateQueries([
          `/connection/${connectionId}/collection?view=REPORT_VIEW`,
          tenant?.tenant_id,
        ]);
        queryClient.invalidateQueries([`/tenant/${tenant.tenant_id}/usage`, tenant.tenant_id]);
      },
    }
  );

  useNotifyError({
    error: mutation.error,
    fallbackMessage: 'Error: Failed to create a bookmark',
  });

  return {
    createBookmarkMutation: mutation,
  };
};

const editBookmark = (formData, tenant, selectedBookmark) =>
  getServiceInstance(tenant?.tenant_id).put(
    `${dataflowApiBase}/connection/${selectedBookmark.connection_id}/bookmark/${selectedBookmark.query_bookmark_id}`,
    formData
  );

export const useEditBookmarkMutaton = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ formData, tenant, selectedBookmark }) => editBookmark(formData, tenant, selectedBookmark),
    {
      onSuccess: (data, { selectedBookmark, tenant, formData }) => {
        const bookmarks = queryClient.getQueryData([
          `/connection/${selectedBookmark.connection_id}/bookmark`,
          tenant?.tenant_id,
        ]);

        const updatedBookmarkIndex = bookmarks.findIndex(
          (bookmark) => bookmark.query_bookmark_id === selectedBookmark.query_bookmark_id
        );

        const updatedBookmarks = produce(bookmarks, (draft) => {
          draft[updatedBookmarkIndex].name = formData.name;
          draft[updatedBookmarkIndex].description = formData.description;
        });

        queryClient.setQueryData(
          [`/connection/${selectedBookmark.connection_id}/bookmark`, tenant?.tenant_id],
          updatedBookmarks
        );
      },
      onSettled: (data, error, { tenant, selectedBookmark }) => {
        queryClient.invalidateQueries([
          `/connection/${selectedBookmark.connection_id}/bookmark`,
          tenant?.tenant_id,
        ]);
      },
    }
  );

  useNotifyError({
    error: mutation.error,
    fallbackMessage: 'Error: Failed to update the bookmark',
  });

  return { editBookmarkMutation: mutation };
};

const moveBookmark = (collectionId, tenant, selectedBookmark) =>
  getServiceInstance(tenant?.tenant_id).put(
    `${dataflowApiBase}/connection/${selectedBookmark.connection_id}/bookmark/${selectedBookmark.query_bookmark_id}`,
    collectionId
  );

export const useMoveBookmarkMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ collectionId, tenant, selectedBookmark }) =>
      moveBookmark(collectionId, tenant, selectedBookmark),
    {
      onSuccess: (data, { selectedBookmark, tenant, view }) => {
        const collections = queryClient.getQueryData([
          `/connection/${selectedBookmark.connection_id}/collection?view=${view}`,
          tenant?.tenant_id,
        ]);

        queryClient.setQueryData(
          [
            `/connection/${selectedBookmark.connection_id}/collection?view=${view}`,
            tenant?.tenant_id,
          ],
          [data, ...collections]
        );
      },
      onSettled: (data, error, { tenant, selectedBookmark, view }) => {
        queryClient.invalidateQueries([
          `/connection/${selectedBookmark.connection_id}/collection?view=${view}`,
          tenant?.tenant_id,
        ]);
      },
    }
  );

  useNotifyError({
    error: mutation.error,
    fallbackMessage: 'Error: Failed to move the bookmark',
  });

  return { moveBookmarkMutation: mutation };
};
