/**
 * @file
 *
 * this file contains the component which render the logs of report view
 */
import {
  Stack,
  Collapse,
  IconButton,
  Typography,
  Link,
  Divider,
  Chip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Tooltip,
} from '@mui/material';
import { useCallback, useState, useMemo, useEffect } from 'react';
import { usePrevious } from 'react-use';
import {
  MdExpandMore,
  MdChevronRight,
  MdContentCopy,
  MdShare,
  MdBookmark,
  MdBookmarkBorder,
} from 'react-icons/md';
import { FiMoreVertical } from 'react-icons/fi';
import dayjs from 'dayjs';
import { entries, groupBy } from 'lodash';

import { FEATURE_CODES, useBillingUsage } from 'data/billingUsage';
import { cast, getBillingExpiryStatus, strip$formatAnd$inlinecountFromUrl } from 'utils';
import { useTenantState, useUser } from 'data/user';
import { useCopy } from 'hooks/useCopy';
import { getKeyRefBasedQueryUrlRegex } from 'odata/queryStringParser';
import { useShareableUrl } from 'state/queryBuilder';
import { ErrorImage } from 'components/Illustrations/Error';
import { NoLogsImage } from 'components/Illustrations/NoLogs';
import { checkBookmarkExists } from 'utils';
import { hydrateReportViewState } from 'state/reportView';
import { initialQueryDataState } from 'components/ReportView';
import { toast } from 'react-hot-toast';
import { LOG_TYPE } from './constants';
import { PERMISSION_CODES } from '../../constants';

function useLogsExpand(logData) {
  const dates = useMemo(
    // This logic groups all the logs according to the date of creation
    () => entries(groupBy(logData, (log) => dayjs(log.created_at).format('MMMM DD'))),
    [logData]
  );

  // if the dates array is not empty, grab the latest date.
  const firstDate = useMemo(() => dates?.[0]?.[0], [dates]);
  const prevFirstDate = usePrevious(firstDate);

  const [expanded, setExpanded] = useState(() => ({
    // this sets the first log-group to expanded by default
    ...(Boolean(firstDate) && { [firstDate]: true }),
  }));

  useEffect(() => {
    // checks if the firstDate and previousDate are same
    // if not set the first date to true so that the first log group is expanded by default when a new query is made
    if (firstDate && prevFirstDate !== firstDate) {
      setExpanded((prevState) => ({ ...prevState, [firstDate]: true }));
    }
  }, [firstDate, prevFirstDate]);

  const toggleExpand = useCallback(
    (id) => {
      setExpanded((prevState) => ({ ...prevState, [id]: !prevState[id] }));
    },
    [setExpanded]
  );

  return {
    dates,
    expanded,
    toggleExpand,
  };
}

const getLogErrorText = ({ error, hasPlanExpired }) => {
  if (error?.msg) {
    return error.msg;
  } else if (hasPlanExpired) {
    return 'Plan has expired for the tenant.';
  }

  return 'Failed to fetch Logs';
};

export default function ReportViewLogsPanel({
  logData,
  bookmarkData,
  bookmarkError,
  isBookmarkLoading,
  isLogLoading,
  logError,
  baseEndpoint,
  schema,
  systemType,
  openCreateBookmarkDialog,
  refetchLogs,
  setQueryData,
}) {
  const [logsByCurrentUser, setLogsByCurrentUser] = useState([]);

  const { data: billingUsage } = useBillingUsage();
  const { hasPlanExpired } = getBillingExpiryStatus(billingUsage?.last_date);
  const user = useUser();

  useEffect(() => {
    refetchLogs();
  }, [refetchLogs]);

  useEffect(() => {
    if (!logData || !Array.isArray(logData) || !logData.length || !user) {
      return;
    }

    const filteredLogs = logData.filter(
      ({ created_by, type }) => type === LOG_TYPE.REPORT_VIEW && created_by === user.user_id
    );

    setLogsByCurrentUser(filteredLogs);
  }, [logData, user]);

  const { dates, expanded, toggleExpand } = useLogsExpand(logsByCurrentUser);

  if (isLogLoading && !hasPlanExpired) {
    return <ReportViewSkeletonLoader />;
  } else if (logError || hasPlanExpired) {
    return (
      <Stack direction="column" alignItems="center" mt={3}>
        <Stack justifyContent="center">
          <ErrorImage width="180px" height="200px" />
        </Stack>
        <Stack justifyContent="center">
          <Typography>{getLogErrorText({ error: logError, hasPlanExpired })}</Typography>
        </Stack>
      </Stack>
    );
  } else if (!logsByCurrentUser || logsByCurrentUser.length === 0) {
    return (
      <Stack direction="column" mt={10} alignItems="center">
        <NoLogsImage width="180px" height="180px" />
        <Typography color="textSecondary">No Logs</Typography>
      </Stack>
    );
  }

  return (
    <>
      <Stack direction="column" sx={{ cursor: 'pointer' }}>
        {dates.map(([date, logs]) => (
          <LogGroup
            key={date}
            date={date}
            logs={logs}
            isExpanded={expanded[date]}
            toggleExpand={toggleExpand}
            bookmarkData={bookmarkData}
            bookmarkError={bookmarkError}
            isBookmarkLoading={isBookmarkLoading}
            baseEndpoint={baseEndpoint}
            schema={schema}
            systemType={systemType}
            openCreateBookmarkDialog={openCreateBookmarkDialog}
            setQueryData={setQueryData}
          />
        ))}
      </Stack>
    </>
  );
}

function LogGroup({
  date,
  logs,
  isExpanded,
  toggleExpand,
  bookmarkData,
  isBookmarkLoading,
  baseEndpoint,
  bookmarkError,
  schema,
  systemType,
  openCreateBookmarkDialog,
  setQueryData,
}) {
  const handleExpand = useCallback(() => {
    toggleExpand(date);
  }, [toggleExpand, date]);

  const lastLogIndex = logs.length - 1;

  return (
    <>
      <Stack direction="row" onClick={handleExpand} alignItems="center" spacing={1} my={1.5} mx={1}>
        <Stack>
          {isExpanded ? <MdExpandMore fontSize={18} /> : <MdChevronRight fontSize={18} />}
        </Stack>
        <Stack>
          <Typography color="textSecondary">{date}</Typography>
        </Stack>
      </Stack>
      <Collapse in={isExpanded} style={{ width: '100%' }}>
        {logs.map((log, currIndex) => (
          <Stack
            direction="row"
            mt={1}
            key={log.query_log_id}
            borderBottom={lastLogIndex === currIndex ? null : '1px solid lightgray'}
          >
            <Log
              key={log.query_log_id}
              log={log}
              bookmarkData={bookmarkData}
              isBookmarkLoading={isBookmarkLoading}
              baseEndpoint={baseEndpoint}
              bookmarkError={bookmarkError}
              schema={schema}
              systemType={systemType}
              openCreateBookmarkDialog={openCreateBookmarkDialog}
              setQueryData={setQueryData}
            />
          </Stack>
        ))}
      </Collapse>
      <Divider variant="fullWidth" />
    </>
  );
}

function Log({
  log,
  schema,
  systemType,
  bookmarkData,
  isBookmarkLoading,
  baseEndpoint,
  bookmarkError,
  openCreateBookmarkDialog,
  setQueryData,
}) {
  const tenant = useTenantState();

  const canCreateBookmark = useMemo(
    () =>
      tenant?.permissions.some(
        ({ permission_code }) => permission_code === PERMISSION_CODES.CREATE_BOOKMARK
      ),
    [tenant?.permissions]
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const openPopupMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closePopupMenu = () => {
    setAnchorEl(null);
  };

  const copyToClipboard = useCopy();
  const { getShareableUrl } = useShareableUrl();

  const { data: billingUsage, isFeatureQuotaExhausted } = useBillingUsage(
    FEATURE_CODES.BOOKMARK_CREATE_COUNT,
    bookmarkData
  );

  const { hasPlanExpired } = getBillingExpiryStatus(billingUsage?.last_date);

  const handleLinkClick = useCallback(() => {
    hydrateReportViewState(log.query_string, baseEndpoint, schema, systemType);
    setQueryData(initialQueryDataState);
  }, [baseEndpoint, log.query_string, schema, setQueryData, systemType]);

  const strippedQueryString = strip$formatAnd$inlinecountFromUrl(log.query_string);
  const decodedQueryString = decodeURIComponent(strippedQueryString);

  const logTimeStamp = cast.date(log.created_at, true).format('HH:mm:ss');

  const regexParsedResult = getKeyRefBasedQueryUrlRegex(strippedQueryString, baseEndpoint);

  const handleCopyShareableURL = useCallback(() => {
    try {
      const url = new URL(decodedQueryString);
      url.searchParams.delete('$orderby');

      const shareableURL = getShareableUrl(url.toString());
      const shareableUrlObj = new URL(shareableURL);

      const odataQuery = shareableUrlObj.searchParams.get('odataQuery');
      const decodedODataQuery = decodeURIComponent(odataQuery);
      const strippedODataQuery = decodedODataQuery.replace(`${baseEndpoint}/`, '');

      shareableUrlObj.searchParams.set('odataQuery', strippedODataQuery);

      copyToClipboard(shareableUrlObj.href);
    } catch (error) {
      toast.error('Copy to clipboard failed!', {
        id: 'copy-shareable-query-error',
        duration: 3000,
      });
    } finally {
      closePopupMenu();
    }
  }, [baseEndpoint, copyToClipboard, decodedQueryString, getShareableUrl]);

  const isAddToBookmarksBtnDisabled =
    isFeatureQuotaExhausted || hasPlanExpired || !canCreateBookmark;

  return (
    <>
      <Stack ml={3} mb={1} direction="row">
        <Stack sx={{ width: '330px' }}>
          <Link
            underline="hover"
            component={Typography}
            color="textSecondary"
            onClick={handleLinkClick}
            sx={(theme) => ({
              p: 1,
              py: 0.5,
              fontSize: theme.spacing(1.5),
              overflowWrap: 'break-word',
              cursor: 'pointer',
            })}
          >
            {decodedQueryString}
          </Link>
          {regexParsedResult && (
            <Stack justifyContent="flex-start">
              <Chip
                component="p"
                size="small"
                variant="outlined"
                color="primary"
                label="includes navigations"
              />
            </Stack>
          )}
          <Typography pt={1} pb={0.5} pl={1} fontStyle="italic" color="GrayText" variant="body2">
            Created on {logTimeStamp}
          </Typography>
        </Stack>
        <Stack>
          <IconButton onClick={openPopupMenu}>
            <FiMoreVertical fontSize={18} />
          </IconButton>
        </Stack>
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={closePopupMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            closePopupMenu();
            copyToClipboard(decodedQueryString);
          }}
        >
          <ListItemIcon>
            <MdContentCopy fontSize="18px" />
          </ListItemIcon>
          <ListItemText primary="Copy Query URL" />
        </MenuItem>
        <MenuItem onClick={handleCopyShareableURL}>
          <ListItemIcon>
            <MdShare fontSize="18px" />
          </ListItemIcon>
          <ListItemText primary="Copy Shareable URL" />
        </MenuItem>
        {!isBookmarkLoading &&
          !bookmarkError &&
          (checkBookmarkExists(bookmarkData, decodedQueryString) ? (
            <MenuItem
              onClick={() => {
                closePopupMenu();
              }}
              disabled={true}
            >
              <ListItemIcon>
                <MdBookmark fontSize="18px" />
              </ListItemIcon>
              <ListItemText primary="Bookmarked" />
            </MenuItem>
          ) : (
            <Tooltip
              title={
                !canCreateBookmark && 'You do not have necessary permissions to create bookmark'
              }
            >
              <span>
                <MenuItem
                  onClick={() => {
                    openCreateBookmarkDialog(decodedQueryString);
                    closePopupMenu();
                  }}
                  disabled={isAddToBookmarksBtnDisabled}
                >
                  <ListItemIcon>
                    <MdBookmarkBorder fontSize="18px" />
                  </ListItemIcon>
                  <ListItemText primary="Add to Bookmarks" />
                </MenuItem>
              </span>
            </Tooltip>
          ))}
      </Menu>
    </>
  );
}

export function ReportViewSkeletonLoader() {
  return (
    <Stack direction="column" spacing={2} alignItems="center" mt={2}>
      {Array(8)
        .fill()
        .map((_, index) => (
          <Stack key={index}>
            <Skeleton variant="rectangular" width={350} height={50} />
          </Stack>
        ))}
    </Stack>
  );
}
