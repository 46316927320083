import { createStore } from '@halka/state';
import {
  Button,
  ButtonGroup,
  Checkbox,
  DialogContentText,
  Stack,
  Tooltip,
  TextField,
  Typography,
} from '@mui/material';
import { get, isEmpty, isEqual, xor } from 'lodash-es';
import { nanoid } from 'nanoid';
import React, { forwardRef, useCallback, useState } from 'react';
import { MdCancel, MdPlayArrow, MdRefresh, MdSettings, MdUndo } from 'react-icons/md';
import { toast } from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import sortArray from 'sort-array';
import { IoMdSwitch } from 'react-icons/io';
import { useEffect } from 'react';

import { cancelAllPendingBatchedRequests } from '../../reportView/main';
import {
  reportViewStateHandlers,
  useOnReportViewStateUpdate,
  useReportViewState,
  useTranslatedQueryUrl,
  updateReportViewState,
} from '../../state/reportView';
import { initialQueryDataState } from '../ReportView';
import {
  FETCH_CANCELLATION,
  FETCH_STATE,
  ROOT_LEVEL_GROUP_NAME,
  STEPPER_FLOWS,
  SYSTEMS,
} from './constants';
import DialogWrapper from 'components/DialogWrapper';
import { useDisclosure } from 'hooks/useDisclosure';
import { constructURL, getSystemConfigurationAndPreferences } from 'utils';
import { useTenantState } from 'data/user';
import { useCreateExportJob } from 'hooks/useCreateExportJob';
import CommonStepperDialog from './CommonStepperDialog';
import PopoverMenu from './PopoverMenu';
import { theme } from 'theme';
import { groupByMultiplicity } from 'reportView/utils';
import { BiInfoCircle } from 'react-icons/bi';
import { PAGE_SIZE_DEFAULTS } from '../../constants';

const previousQueryKeyStore = createStore(null);

export const useReportViewActions = ({
  schema,
  resetPagination,
  returnToFirstPage,
  fetchPaginatedDataFromDatabase,
  resetKeyHashKeeper,
  setQueryData,
  queryData,
  isSfSystem,
  ref,
  openNonVisFieldsWarningDialog,
  setNonVisFieldsDialogInfo,
  connection,
  openSystemConfigurationDialog,
  closeSystemConfigurationDialog,
  initializeDatabase,
}) => {
  const { current, fork } = useReportViewState();

  useOnReportViewStateUpdate({ isSfSystem, schema });
  const translatedQueryUrl = useTranslatedQueryUrl();

  const isStateStale = !isEqual(current, fork);
  const hasFetchFailed = queryData.fetchState === FETCH_STATE.FAILED;
  const wasFetchCanceled = queryData.fetchState === FETCH_STATE.CANCELED;
  const hasFetchCompleted = queryData.fetchState === FETCH_STATE.FETCHED;
  const isFetchingData = queryData.fetchState === FETCH_STATE.FETCHING;
  const isIdle = queryData.fetchState === FETCH_STATE.IDLE;
  const hasValidTopAndSkipValues = fork?.top !== null && fork?.skip !== null;

  // If the state is stale but the current state has some data then we should the undo button
  const showUndoButton = isStateStale && !isEmpty(current.entity.value);

  // The run button should only be enabled if the following conditions meet:
  // 1. An entity is seleted (this is mandatory)
  // 2. Top and Skip values should be valid (this is mandatory)
  // 3. If the fetch failed - so that users can run the query again
  // 4. If the fetch was cancelled - so that the users can run the query again
  // 5. If the fetchState is idle - when RE is idle, the button should be enabled
  // 6. If the data is not being fetched currently, should allow the users to retrigger
  //    a query when the older query is still loading
  const isRunEnabled =
    fork.entity.value &&
    hasValidTopAndSkipValues &&
    (hasFetchFailed || wasFetchCanceled || isIdle || !isFetchingData);

  const hasAsOfDateChanged = current.effectiveRange?.asOfDate !== fork.effectiveRange?.asOfDate;
  const hasFromDateChanged = current.effectiveRange?.fromDate !== fork.effectiveRange?.fromDate;
  const hasToDateChanged = current.effectiveRange?.toDate !== fork.effectiveRange?.toDate;
  const hasEffectiveRangeChanged = hasAsOfDateChanged || hasFromDateChanged || hasToDateChanged;
  const hasEntityChanged = current.entity.value !== fork.entity.value;
  const hasExpandsChanged = xor(current.expand.value, fork.expand.value).length !== 0;
  const hasFiltersChanged = xor(current.where.value.rules, fork.where.value.rules).length !== 0;
  const hasTopChanged = current.top !== fork.top;
  const hasVisibleFieldsChanged =
    xor(current.visibleFields.value, fork.visibleFields.value).length !== 0;
  const hasSortByChanged = xor(current.sortBy.value, fork.sortBy.value).length !== 0;

  const shouldIntializeNewDatabase =
    hasFetchFailed ||
    wasFetchCanceled ||
    hasEntityChanged ||
    hasExpandsChanged ||
    hasFiltersChanged ||
    hasVisibleFieldsChanged ||
    hasSortByChanged ||
    hasTopChanged ||
    hasEffectiveRangeChanged ||
    hasFetchCompleted;

  const queryClient = useQueryClient();
  const tenant = useTenantState();
  const userSystemEndpoint = `/user-system?systemCode=${SYSTEMS.SF_EC.KEY}`;

  const triggerDataFetch = useCallback(
    async (forceFetch, viewPicklistValues = false, triggeredViaPaginationBtn = false) => {
      if (viewPicklistValues) {
        const userSystems = await queryClient.getQueryData([userSystemEndpoint, tenant?.tenant_id]);
        const { metadata, picklist } = getSystemConfigurationAndPreferences(
          userSystems,
          connection?.user_system_id
        );
        if (!picklist || !metadata) {
          openSystemConfigurationDialog();
          toast.error('Metadata or Picklist is not configured');
          return;
        }
      }
      closeSystemConfigurationDialog();

      const { fork, current: currentBeforeUpdate } = useReportViewState.get();

      const url = constructURL(translatedQueryUrl);
      if (!url) {
        toast.error('Failed to execute query - invalid query');
        return;
      }

      // remove inaccessible columns and order expands
      let filteredUrl = processColumns(
        fork,
        url,
        openNonVisFieldsWarningDialog,
        setNonVisFieldsDialogInfo
      );

      const shouldRefreshSkip = shouldIntializeNewDatabase && !triggeredViaPaginationBtn;

      if (shouldRefreshSkip) {
        filteredUrl = modifyTopAndSkipValues(
          filteredUrl,
          fork,
          currentBeforeUpdate,
          shouldRefreshSkip
        );
      }

      if (!triggeredViaPaginationBtn) {
        reportViewStateHandlers.updateCurrentState();
      }

      const { current } = useReportViewState.get();

      if (triggeredViaPaginationBtn) {
        filteredUrl = modifyTopAndSkipValues(filteredUrl, fork, current);
      }

      resetPagination();
      resetKeyHashKeeper();

      if (shouldIntializeNewDatabase || forceFetch) {
        // Everytime the user clicks on the Run button and is a new database is getting
        // initialized that means it is a new query and for every new query, we need a new
        // queryKey to differentiate between two subsequent triggers.
        setQueryData((state) => {
          previousQueryKeyStore.set(state.key);

          return { ...initialQueryDataState, key: nanoid(), fetchState: FETCH_STATE.FETCHING };
        });
        await initializeDatabase(current, filteredUrl)
          .then((arePendingBatchesTerminated) => {
            const fetchState = arePendingBatchesTerminated
              ? FETCH_STATE.FETCHING
              : FETCH_STATE.FETCHED;
            setQueryData((state) => ({ ...state, fetchState }));
          })
          .catch((error) => {
            let parsedError;

            try {
              parsedError = JSON.parse(error.message);
            } catch {
              parsedError = error.message;
            }

            // If the cancellation was an initial query cancellation, then we want to set
            // the queryData set to the initialState
            if (error.message.includes(FETCH_CANCELLATION.CANCEL_QUERY_TRIGGER)) {
              setQueryData({
                ...initialQueryDataState,
                fetchState: FETCH_STATE.CANCELED,
                error: parsedError,
              });
              return;
            }

            // If the cancellation was to cancel all the pending batches then we just set the
            // fetchState to cancel while preserving the rest of the state
            if (error.message.includes(FETCH_CANCELLATION.CANCEL_ALL_BATCHES)) {
              setQueryData((state) => ({
                ...state,
                fetchState: FETCH_STATE.CANCELED,
                error: parsedError,
              }));
              return;
            }

            setQueryData((state) => {
              return {
                ...state,
                fetchState: FETCH_STATE.FAILED,
                error: parsedError,
              };
            });
          });

        return;
      }

      returnToFirstPage();
      await fetchPaginatedDataFromDatabase(0, true);
    },
    [
      queryClient,
      userSystemEndpoint,
      tenant?.tenant_id,
      connection?.user_system_id,
      closeSystemConfigurationDialog,
      translatedQueryUrl,
      openNonVisFieldsWarningDialog,
      setNonVisFieldsDialogInfo,
      resetPagination,
      resetKeyHashKeeper,
      shouldIntializeNewDatabase,
      returnToFirstPage,
      fetchPaginatedDataFromDatabase,
      openSystemConfigurationDialog,
      setQueryData,
      initializeDatabase,
    ]
  );

  ref.current = triggerDataFetch;

  return { showUndoButton, triggerDataFetch, isRunEnabled };
};

export const ActionButtons = forwardRef(
  (
    {
      schema,
      resetPagination,
      resetKeyHashKeeper,
      queryData,
      setQueryData,
      isSfSystem,
      returnToFirstPage,
      fetchPaginatedDataFromDatabase,
      connection,
      initializeDatabase,
      viewPicklistValues,
      toggleViewPicklistCheckbox,
      setPageNumber,
      canCreateQuery,
      pageSize,
      setPageSize,
    },
    ref
  ) => {
    const {
      isOpen: isNonVisFieldsWarningDialogOpen,
      open: openNonVisFieldsWarningDialog,
      close: closeNonVisFieldsWarningDialog,
    } = useDisclosure();
    const [nonVisFieldsDialogInfo, setNonVisFieldsDialogInfo] = useState('');

    const [popperAnchorEle, setPopperAnchorEl] = useState(null);
    const toggleRunOptionsPopper = useCallback(
      (event) => {
        setPopperAnchorEl(popperAnchorEle ? null : event.currentTarget);
      },
      [popperAnchorEle]
    );
    const isRunOptionsPopperOpen = Boolean(popperAnchorEle);

    const {
      isOpen: isSystemConfigurationDialogOpen,
      open: openSystemConfigurationDialog,
      close: closeSystemConfigurationDialog,
    } = useDisclosure();

    const { showPicklistValues, handleShowPicklistValues } = useCreateExportJob(); //for create export job

    const { showUndoButton, triggerDataFetch, isRunEnabled } = useReportViewActions({
      schema,
      resetPagination,
      resetKeyHashKeeper,
      setQueryData,
      queryData,
      isSfSystem,
      returnToFirstPage,
      fetchPaginatedDataFromDatabase,
      ref,
      openNonVisFieldsWarningDialog,
      setNonVisFieldsDialogInfo,
      connection,
      openSystemConfigurationDialog,
      viewPicklistValues,
      closeSystemConfigurationDialog,
      initializeDatabase,
    });

    const { undoForkState, resetForkState } = reportViewStateHandlers;

    // If the data is being fetched, we want to show the loader but only till there is some data to be shown.
    // If data.length>0, then we stop the loader. We also want to show a loader if we are running a query.
    // There could be scenarios where the data returned from query is 0 and the FETCHING is still going on. In this
    // scenario we have to check whether the QUERYING was successful or not
    const isFetchingData = queryData.fetchState === FETCH_STATE.FETCHING;
    const isLoadingData = queryData.data.length === 0 && isFetchingData;

    const handleCancelRequest = useCallback(() => {
      cancelAllPendingBatchedRequests(FETCH_CANCELLATION.CANCEL_ALL_BATCHES);
      setQueryData((state) => ({ ...state, fetchState: FETCH_STATE.IDLE }));
    }, [setQueryData]);

    const triggerFetchWithoutExCodes = useCallback(() => {
      toggleViewPicklistCheckbox(false);
      triggerDataFetch(false);
    }, [toggleViewPicklistCheckbox, triggerDataFetch]);

    const handleRunBtnClick = useCallback(() => {
      setPageNumber(1);
      triggerDataFetch(false, viewPicklistValues);
    }, [setPageNumber, triggerDataFetch, viewPicklistValues]);

    return (
      <>
        <Stack direction="row" spacing={1} alignItems="center" ml={1}>
          <ButtonGroup
            variant="contained"
            disableElevation
            size="small"
            color={isFetchingData ? 'error' : 'primary'}
          >
            <Button
              disabled={!isFetchingData && !isRunEnabled}
              type="submit"
              onClick={isFetchingData ? handleCancelRequest : handleRunBtnClick}
              startIcon={isFetchingData ? <MdCancel /> : <MdPlayArrow />}
            >
              {isFetchingData ? 'Cancel' : 'Run'}
            </Button>
            <Button
              onClick={toggleRunOptionsPopper}
              disabled={isFetchingData || !isRunEnabled}
              style={{ minWidth: '30px' }}
              sx={{
                '&.Mui-disabled': {
                  background: isFetchingData ? theme.palette.error.dark : 'primary',
                },
              }}
            >
              <MdSettings style={{ fontSize: '14' }} />
            </Button>
            {isRunOptionsPopperOpen && (
              <PopoverMenu
                sx={{ width: 300 }}
                isOpen={isRunOptionsPopperOpen}
                anchorEl={popperAnchorEle}
                children={
                  <RunOptions
                    viewPicklistValues={viewPicklistValues}
                    toggleViewPicklistCheckbox={toggleViewPicklistCheckbox}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                  />
                }
                close={toggleRunOptionsPopper}
              />
            )}
          </ButtonGroup>
          {showUndoButton ? (
            <Button disabled={isLoadingData} startIcon={<MdUndo />} onClick={undoForkState}>
              Undo
            </Button>
          ) : (
            <Button
              disabled={isLoadingData}
              startIcon={<MdRefresh />}
              onClick={() => {
                setPageNumber(1);
                resetForkState();
                setQueryData((state) => (state.error ? initialQueryDataState : state));
              }}
            >
              Reset
            </Button>
          )}
          {!canCreateQuery && (
            <Stack>
              <Tooltip title="You do not have permission to create custom queries. You can still run queries and view data using bookmarks">
                <span style={{ paddingTop: '3px' }}>
                  <BiInfoCircle style={{ fontSize: 18, color: '#44a6c6' }} />
                </span>
              </Tooltip>
            </Stack>
          )}
        </Stack>
        <DialogWrapper
          isOpen={isNonVisFieldsWarningDialogOpen}
          closeDialog={closeNonVisFieldsWarningDialog}
          title="Removed non-viewable fields"
          secondaryBtnAction={closeNonVisFieldsWarningDialog}
        >
          <Stack justifyContent="center" width="100%" alignItems="center">
            <DialogContentText>
              Property <strong>{nonVisFieldsDialogInfo}</strong> was removed from the query as it is
              marked as <strong>not visible</strong> as per SuccessFactors configuration
            </DialogContentText>
          </Stack>
        </DialogWrapper>
        <CommonStepperDialog
          connection={connection}
          isCommonStepperDialogOpen={isSystemConfigurationDialogOpen}
          closeCommonStepperDialog={closeSystemConfigurationDialog}
          finalActionBtnProps={{
            btnAction: triggerFetchWithoutExCodes,
            btnText: 'Run Query',
            btnProps: null,
          }}
          stepperFlowProps={{
            showPicklistValues: showPicklistValues,
            handleShowPicklistValues: handleShowPicklistValues,
          }}
          stepperFlow={STEPPER_FLOWS.SYSTEM_CONFIGURATION.KEY}
        />
      </>
    );
  }
);

function RunOptions({ viewPicklistValues, toggleViewPicklistCheckbox, pageSize, setPageSize }) {
  const [localPageSize, setLocalPageSize] = useState(pageSize); // local state of text field
  const { handleUpdateTopValue } = reportViewStateHandlers;

  const handlePageSizeChange = useCallback(
    (event) => {
      const newPageSize = event.target.value;
      setLocalPageSize(newPageSize);

      if (
        newPageSize >= PAGE_SIZE_DEFAULTS.LOWER_LIMIT &&
        newPageSize <= PAGE_SIZE_DEFAULTS.UPPER_LIMIT
      ) {
        setPageSize(Number(newPageSize));
        handleUpdateTopValue(Number(newPageSize));
      }
    },
    [handleUpdateTopValue, setPageSize]
  );

  const [isPageSizeValueOutOfBounds, setIsPageSizeValueOutOfBounds] = useState(false);

  useEffect(() => {
    if (
      localPageSize > PAGE_SIZE_DEFAULTS.UPPER_LIMIT ||
      localPageSize < PAGE_SIZE_DEFAULTS.LOWER_LIMIT
    ) {
      setIsPageSizeValueOutOfBounds(true);
      return;
    }
    setIsPageSizeValueOutOfBounds(false);
  }, [localPageSize]);

  return (
    <Stack>
      <Stack direction="row" alignItems={'center'} sx={{ mb: 1 }}>
        <span>
          <IoMdSwitch style={{ fontSize: 18, color: '#354A5F' }} />
        </span>
        <Typography sx={{ ml: 1, pb: 0.5 }}>
          <strong>Query Options</strong>
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
        <Typography> Show external codes</Typography>
        <Checkbox checked={viewPicklistValues} onChange={toggleViewPicklistCheckbox} />
      </Stack>
      <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
        <Typography>Page Size</Typography>
        <TextField
          size="small"
          type="number"
          value={localPageSize}
          error={isPageSizeValueOutOfBounds}
          inputProps={{
            min: PAGE_SIZE_DEFAULTS.LOWER_LIMIT,
            max: PAGE_SIZE_DEFAULTS.UPPER_LIMIT,
          }}
          helperText={
            isPageSizeValueOutOfBounds &&
            `${PAGE_SIZE_DEFAULTS.LOWER_LIMIT} - ${PAGE_SIZE_DEFAULTS.UPPER_LIMIT}` // helper text example: "10 - 1000"
          }
          sx={{
            width: 80,
            '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
              {
                opacity: 1, // Keeps spinner buttons visible at all times
              },
          }}
          InputProps={{ sx: { height: 25 } }}
          onChange={handlePageSizeChange}
        />
      </Stack>
    </Stack>
  );
}

// This function removes the inaccessible fields and orders the fields
// in this format: root columns -> 1 mul expand columns -> n mul expand columns
function processColumns(
  forkedState,
  url,
  openNonVisFieldsWarningDialog,
  setNonVisFieldsDialogInfo
) {
  // we need to remove 'non-visible' fields before triggering the Query.
  const visibleFieldsValue = forkedState.visibleFields.value;
  const visbileFieldsOptionsMap = forkedState.visibleFields.optionsMap;
  const selectedFields = visibleFieldsValue.map((fieldKey) =>
    get(visbileFieldsOptionsMap, fieldKey)
  );

  // seperate the invisible and visible fields from the selected-fields
  const { visibleFieldsArr, invisibleFieldsArr } = selectedFields.reduce(
    (acc, { visible, name }) => {
      if (visible) {
        acc.visibleFieldsArr.push(name);
        return acc;
      }

      acc.invisibleFieldsArr.push(name);
      return acc;
    },
    { visibleFieldsArr: [], invisibleFieldsArr: [] }
  );

  // set the visible fields in the url $select param
  const params = new URLSearchParams(url.search);

  if (visibleFieldsArr.length > 0) {
    params.delete('$select');
    params.set('$select', visibleFieldsArr.join(','));
  }

  url.search = params.toString();

  // If invisible fields are present, then show the warning dialog and update the query state
  if (invisibleFieldsArr.length > 0) {
    setNonVisFieldsDialogInfo(invisibleFieldsArr.join(', '));

    const visibleFieldKeys = visibleFieldsArr.map((field) =>
      field.includes('/') ? field.replace('/', '.') : `${ROOT_LEVEL_GROUP_NAME}.${field}`
    );

    openNonVisFieldsWarningDialog();

    updateReportViewState((state) => {
      state.fork.visibleFields.value = visibleFieldKeys;
    });
  }

  // Ordering expands logic

  const areExpandsSelected = params.get('$expand') !== null;

  if (!areExpandsSelected) {
    return url.href;
  }

  const expandOptions = forkedState.expand.options;
  const selectedExpands = forkedState.expand.value;

  const selectedExpandsData = expandOptions.filter((expand) =>
    selectedExpands.includes(expand.name)
  );

  // to update the expands order in query summary and query url
  sortArray(selectedExpandsData, {
    by: 'multiplicity',
    order: 'desc',
  });

  const orderedExpands = selectedExpandsData.map((expand) => expand.name);
  params.delete('$expand');
  params.set('$expand', orderedExpands.join(','));
  url.search = params.toString(); // update query url

  const visibleFieldsData = selectedFields.filter((field) => visibleFieldsArr.includes(field.name));

  // We want to group the fields by multiplicity and then sort it according to multiplicity.
  // This way we preserve the user's 'arrange fields' config
  const groupedColumnsData = groupByMultiplicity(visibleFieldsData);
  sortArray(groupedColumnsData, {
    by: 'multiplicity',
    order: 'desc',
    customOrders: {
      multiplicity: ['base', '1', '0..1', '*'],
    },
  });

  // after the grouped columns are sorted according to their multiplicity, we
  // flatten out the columns data.
  const orderedColumnsData = groupedColumnsData.flatMap((obj) => obj.items);

  const updatedVisibleFieldsValue = orderedColumnsData.map((field) => {
    if (field.multiplicity) {
      return field.name.replace('/', '.');
    }
    return `${ROOT_LEVEL_GROUP_NAME}.${field.name}`;
  });

  updateReportViewState((state) => {
    state.fork.expand.value = orderedExpands;
    state.fork.visibleFields.value = updatedVisibleFieldsValue;
  });

  return url.href;
}

/**
 * Modifies the query parameters `$top` and `$skip` in the given URL and returns the updated URL as a string.
 *
 * @param {string} filteredUrl - The URL to be modified.
 * @param {Object} fork - report view state that has latest top and skip values.
 * @returns {string} - The modified URL with updated `$top` and `$skip` query parameters.
 *
 */
const modifyTopAndSkipValues = (filteredUrl, fork, current = {}, shouldRefreshSkip = false) => {
  const { handleUpdateSkipValue } = reportViewStateHandlers;

  const urlObj = new URL(filteredUrl);

  if (shouldRefreshSkip) {
    urlObj.searchParams.set('$top', fork.top);
    urlObj.searchParams.set('$skip', 0);
    handleUpdateSkipValue(0);
  } else {
    urlObj.searchParams.set('$top', current.top);
    urlObj.searchParams.set('$skip', fork.skip);
  }

  return urlObj.href;
};
