/**
 * @file
 *
 * Component that houses pagination controls
 */

import { Box, Typography } from '@material-ui/core';
import { IconButton, Stack } from '@mui/material';
import { LOCAL_STORAGE_KEYS } from '../../../constants';

import { useCallback } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import { reportViewStateHandlers, useReportViewState } from 'state/reportView';

export default function PaginationControls({
  batchState,
  viewPicklistValues,
  triggerDataFetch,
  pageNumber,
  setPageNumber,
}) {
  const { fork, current } = useReportViewState.get();

  const currentTop = current.top;

  const pageSize = Number(localStorage.getItem(LOCAL_STORAGE_KEYS.QUERY_SETTINGS.PAGE_SIZE));

  const { entity, skip } = fork;

  const { totalRecords } = batchState;

  const isEntitySelected = entity?.value;

  const isLastPage = pageNumber === Math.ceil(totalRecords / pageSize);
  const isFirstPage = pageNumber === 1;

  const handleNextPageClick = useCallback(() => {
    setPageNumber((pageNumber) => pageNumber + 1);

    reportViewStateHandlers.handleUpdateSkipValue(skip + currentTop);

    triggerDataFetch(false, viewPicklistValues, true);
  }, [currentTop, setPageNumber, skip, triggerDataFetch, viewPicklistValues]);

  const handlePrevPageClick = useCallback(() => {
    setPageNumber((pageNumber) => pageNumber - 1);

    reportViewStateHandlers.handleUpdateSkipValue(skip - currentTop);

    triggerDataFetch(false, viewPicklistValues, true);
  }, [currentTop, setPageNumber, skip, triggerDataFetch, viewPicklistValues]);

  const isPrevButtonDisabled = !isEntitySelected || isFirstPage;
  const isNextButtonDisabled = !isEntitySelected || isLastPage;

  return (
    <Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box fontSize="0.7rem" color="#ababab" paddingRight={1}>
          {`Displaying ${currentTop} Records per Page`}
        </Box>

        <IconButton
          variant="outlined"
          size="small"
          onClick={handlePrevPageClick}
          disabled={isPrevButtonDisabled}
          color="primary"
          sx={{
            border: isPrevButtonDisabled ? '1px solid #E6E6E6' : '1px solid #6d87a1',
            borderRadius: 1,
          }}
        >
          <MdChevronLeft />
        </IconButton>

        <Typography variant="body2">{` ${pageNumber} / ${Math.ceil(
          totalRecords / currentTop
        )}`}</Typography>

        <IconButton
          variant="outlined"
          size="small"
          disabled={isNextButtonDisabled}
          onClick={handleNextPageClick}
          color="primary"
          sx={{
            border:
              isNextButtonDisabled || !isEntitySelected || isLastPage
                ? '1px solid #E6E6E6'
                : '1px solid #6d87a1',
            borderRadius: 1,
          }}
        >
          <MdChevronRight />
        </IconButton>
      </Stack>
    </Stack>
  );
}
