/**
 * @file
 *
 * The component that renders the query summary in report view
 */
import { CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { BiDownload } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { MdCheckCircle, MdClose } from 'react-icons/md';

import {
  reportViewStateHandlers,
  useQueryUrl,
  useReportViewState,
  useTranslatedQueryUrl,
} from '../../state/reportView';
import { embeddEffectiveRange } from 'odata/reportView';
import { useConnection } from 'data/connectionsAndSystems';
import { SYSTEMS } from '../../constants';
import { constructURL, extractEntityName, getJobsInProgress } from 'utils';
import BookmarkButton from './BookmarkButton';
import ExpandableTypography from 'components/ExpandableTypography';
import DialogWrapper from 'components/DialogWrapper';
import { useDisclosure } from 'hooks/useDisclosure';

export default function QuerySummary({
  reportViewBookmarks,
  isLoadingData,
  openCreateBookmarkDialog,
  openExportJobDialog,
  isCreatingJob,
  openDrawer,
  updateDrawerTabIndex,
  schema,
  isBookmarksQuotaExhausted,
}) {
  const params = useParams();
  const { selectedConnection: connection } = useConnection(params.connectionId);
  const isODataSystem = connection.user_system.system.startsWith(SYSTEMS.ODATA_SERVICE);
  const queryUrl = useQueryUrl();
  const translatedQueryUrl = useTranslatedQueryUrl();

  const queryClient = useQueryClient();

  const queryUrlObj = constructURL(queryUrl);

  const entity = extractEntityName(queryUrlObj);

  const expands = queryUrlObj?.searchParams.get('$expand') ?? '';
  const noOfExpands = expands ? expands.split(',')?.length : 0;

  const asOfDate = queryUrlObj?.searchParams.get('asOfDate') ?? '';
  const fromDate = queryUrlObj?.searchParams.get('fromDate') ?? '';
  const toDate = queryUrlObj?.searchParams.get('toDate') ?? '';
  const balanceAsOf = queryUrlObj?.searchParams.get('balanceAsOf') ?? '';
  const effectiveRangeQuery = embeddEffectiveRange(asOfDate, fromDate, toDate, balanceAsOf);

  const whereQuery = queryUrlObj?.searchParams.get('$filter') ?? '';
  const selectQuery = joinString(queryUrlObj?.searchParams.get('$select')) ?? '';
  const expandQuery = joinString(expands) ?? '';
  const orderByQuery = joinString(queryUrlObj?.searchParams.get('$orderby')) ?? '';

  const [isExportDialogOpening, setIsExportDialogOpening] = useState(false);

  const {
    isOpen: isDuplicateJobWarningDlgOpen,
    close: closeDuplicateJobWarningDlg,
    open: openDuplicateJobWarningDlg,
  } = useDisclosure();

  const {
    isOpen: isExpandsLimitsDlgOpen,
    close: closeExpandsLimitsDlg,
    open: openExpandsLimitDlg,
  } = useDisclosure();

  const handleExportBtnClick = useCallback(async () => {
    setIsExportDialogOpening(true);

    const { connectionId, tenantId } = params;
    const jobs = queryClient.getQueryData([`/connection/${connectionId}/job`, tenantId]);

    const jobsInProgress = getJobsInProgress(jobs);

    // check if there is already an entity whose export job is in-progress
    const entityAlreadyInProgress = jobsInProgress.some((job) => {
      const queryUrlObj = constructURL(job.additional_info.query_string);
      const entityFromJob = extractEntityName(queryUrlObj);

      return entityFromJob === entity;
    });

    if (entityAlreadyInProgress) {
      openDuplicateJobWarningDlg();
      setIsExportDialogOpening(false);

      return;
    }

    if (noOfExpands > 10) {
      openExpandsLimitDlg();
      setIsExportDialogOpening(false);
      return;
    }

    openExportJobDialog(translatedQueryUrl);
    setIsExportDialogOpening(false);
  }, [
    entity,
    noOfExpands,
    openDuplicateJobWarningDlg,
    openExpandsLimitDlg,
    openExportJobDialog,
    params,
    queryClient,
    translatedQueryUrl,
  ]);

  // primary button action for duplicate job warning dialog
  const handlePrimaryBtnAction = useCallback(() => {
    openDrawer();
    updateDrawerTabIndex(null, 2);
    closeDuplicateJobWarningDlg();
  }, [closeDuplicateJobWarningDlg, openDrawer, updateDrawerTabIndex]);

  // primary button action for expands limit dialog
  const handleOpenExportDialog = useCallback(() => {
    openExportJobDialog(translatedQueryUrl);
    closeExpandsLimitsDlg();
  }, [closeExpandsLimitsDlg, openExportJobDialog, translatedQueryUrl]);

  return (
    <>
      <Stack width="100%" direction="row" px={2} py={1}>
        <Stack
          direction="row"
          p={1}
          pl={2}
          borderRadius={1}
          sx={{ backgroundColor: '#ededed' }}
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          width="100%"
        >
          <ExpandableTypography>
            <Typography
              component="span"
              display="inline"
              color="#9b0303"
              sx={{ backgroundColor: '#ffbfbf', borderRadius: 1, px: 1, py: 0.3 }}
              fontFamily="monospace"
            >
              entity
            </Typography>{' '}
            {entity ?? <i>Select an entity to continue</i>}{' '}
            {expandQuery && (
              <>
                <Typography
                  component="span"
                  display="inline"
                  color="#034c9b"
                  sx={{ backgroundColor: '#bfdbff', borderRadius: 1, px: 1, py: 0.3 }}
                  fontFamily="monospace"
                >
                  navigations
                </Typography>{' '}
                {expandQuery};{' '}
              </>
            )}
            {effectiveRangeQuery && (
              <>
                <Typography
                  component="span"
                  display="inline"
                  color="#70005b"
                  sx={{ backgroundColor: '#febfff', borderRadius: 1, px: 1, py: 0.3 }}
                  fontFamily="monospace"
                >
                  effective range
                </Typography>{' '}
                {effectiveRangeQuery};{' '}
              </>
            )}
            {whereQuery && (
              <>
                <Typography
                  component="span"
                  display="inline"
                  color="#705f00"
                  sx={{ backgroundColor: '#fff2bf', borderRadius: 1, px: 1, py: 0.3 }}
                  fontFamily="monospace"
                >
                  filters
                </Typography>{' '}
                {whereQuery};{' '}
              </>
            )}
            {selectQuery && (
              <>
                <Typography
                  component="span"
                  display="inline"
                  color="#9b4703"
                  sx={{ backgroundColor: '#ffe0bf', borderRadius: 1, px: 1, py: 0.3 }}
                  fontFamily="monospace"
                >
                  columns
                </Typography>{' '}
                {selectQuery}{' '}
              </>
            )}
            {orderByQuery && (
              <>
                <Typography
                  component="span"
                  display="inline"
                  color="#126934"
                  sx={{ backgroundColor: '#99deb4', borderRadius: 1, px: 1, py: 0.3 }}
                  fontFamily="monospace"
                >
                  order by
                </Typography>{' '}
                {orderByQuery};{' '}
              </>
            )}
          </ExpandableTypography>
          {entity && (
            <Stack height="100%" alignItems="flex-end" justifyContent="space-between">
              <Stack direction="row" spacing={1} alignItems="center">
                <BookmarkButton
                  queryUrl={queryUrl}
                  reportViewBookmarks={reportViewBookmarks}
                  isLoadingData={isLoadingData}
                  openCreateBookmarkDialog={openCreateBookmarkDialog}
                  isBookmarksQuotaExhausted={isBookmarksQuotaExhausted}
                />

                <IconButton
                  onClick={handleExportBtnClick}
                  disabled={isODataSystem}
                  size="small"
                  color="primary"
                >
                  {isExportDialogOpening || isCreatingJob ? (
                    <CircularProgress size={18} />
                  ) : (
                    <BiDownload fontSize="18px" />
                  )}
                </IconButton>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
      {isDuplicateJobWarningDlgOpen && (
        <DialogWrapper
          isOpen={isDuplicateJobWarningDlgOpen}
          closeDialog={closeDuplicateJobWarningDlg}
          title={`Export for ${entity} in progress`}
          children={
            'The data for this entity is already being exported. Kindly wait for the existing process to complete before trying again.'
          }
          primaryBtnAction={handlePrimaryBtnAction}
          primaryBtnText="Open Jobs panel"
          secondaryBtnAction={closeDuplicateJobWarningDlg}
          size={'xs'}
        />
      )}

      {isExpandsLimitsDlgOpen && (
        <DialogWrapper
          isOpen={isExpandsLimitsDlgOpen}
          closeDialog={closeExpandsLimitsDlg}
          title={'Expands limit exceeded'}
          children={<ExpandTiles schema={schema} />}
          secondaryBtnText="Close"
          secondaryBtnAction={closeExpandsLimitsDlg}
          primaryBtnProps={{ disabled: noOfExpands > 10 }}
          primaryBtnAction={handleOpenExportDialog}
          primaryBtnText="Export"
          size={'xs'}
        />
      )}
    </>
  );
}

function joinString(str, merger = ', ', useAnd = true) {
  const arr = (str ?? '').split(',');

  return arr.reduce((acc, curr, index) => {
    if (index === 0) {
      return curr;
    } else if (index === arr.length - 1 && useAnd) {
      return `${acc} and ${curr}`;
    } else {
      return `${acc}${merger}${curr}`;
    }
  }, '');
}

function ExpandTiles({ schema }) {
  const { fork } = useReportViewState.get();
  const { handleToggleNavExpansion } = reportViewStateHandlers;

  const expandsArray = fork.expand.value;
  const noOfExpands = expandsArray.length;
  const isOverLimit = noOfExpands > 10;

  const handleRemoveExpand = useCallback(
    (event, status) => {
      handleToggleNavExpansion(schema)(event, status);
    },
    [handleToggleNavExpansion, schema]
  );

  return (
    <Stack>
      <Stack
        pb={2}
        sx={{
          position: 'sticky',
          top: '0',
          backgroundColor: 'white',
          zIndex: 10000,
        }}
      >
        {isOverLimit ? (
          <Typography>
            You have selected <strong>{noOfExpands}</strong> expands, but only a maximum of 10
            expands can be exported at a time. Please reduce your selection to 10 or fewer to
            proceed.
          </Typography>
        ) : (
          <Typography sx={{ display: 'flex', alignItems: 'center' }}>
            <MdCheckCircle style={{ color: 'green', marginRight: 3 }} /> Click on Export to Continue
          </Typography>
        )}
      </Stack>
      <Stack>
        {expandsArray.map((eachExpand) => (
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            key={`expand-limit-dlg-${eachExpand}`}
            sx={{
              border: '1px solid',
              borderColor: 'grey.300',
              padding: 1,
              marginBottom: 1,
              borderRadius: 1.5,
            }}
          >
            <Typography>{eachExpand}</Typography>
            <IconButton onClick={handleRemoveExpand} name={eachExpand} size="small">
              <MdClose fontSize={'12px'} />
            </IconButton>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}
