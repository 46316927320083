import React, { useRef } from 'react';
import { Badge, Box, Button, Stack, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { MdChevronRight, MdKeyboardArrowDown, MdVpnKey } from 'react-icons/md';
import { keys, pickBy } from 'lodash-es';

import { useDisclosure } from '../../hooks/useDisclosure';
import { VirtualTree, useFilterFields } from './VirtualTree';
import { reportViewStateHandlers, useReportViewState } from '../../state/reportView';
import { extractPropertyName } from '../../utils';
import PopoverMenu from './PopoverMenu';
import { commonTextFieldProps } from '../../mui5Theme';
import { ROOT_LEVEL_GROUP_NAME } from './constants';

const entityValueSelector = (state) => state.fork.entity.value;
const visibleFieldsSliceSelector = (state) => state.fork.visibleFields;
export function VisibleFieldsOption({ disabled }) {
  const ref = useRef();

  const { isOpen, open, close } = useDisclosure();

  const entityValue = useReportViewState(entityValueSelector);
  const {
    options: visibleFieldsOptions,
    value: visibleFieldsValues,
    optionsMap: visibleFieldsOptionsMap,
  } = useReportViewState(visibleFieldsSliceSelector);

  const { inputValue, groupedFields, handleInputChange } = useFilterFields({
    options: visibleFieldsOptions,
    groupByIteratee: (field) => field?.parent ?? ROOT_LEVEL_GROUP_NAME,
  });

  const shouldRenderVirtualTree = groupedFields.length !== 0;

  return (
    <>
      <Badge
        badgeContent={visibleFieldsValues.length}
        color="primary"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiBadge-badge': {
            top: 5,
            left: 5,
          },
          ml: 1,
        }}
      >
        <Button
          disabled={!entityValue || disabled}
          ref={ref}
          onClick={open}
          size="small"
          variant="outlined"
          color="primary"
        >
          Visible Fields
        </Button>
      </Badge>
      {isOpen && (
        <PopoverMenu isOpen={isOpen} close={close} anchorEl={ref.current} sx={{ pr: 0 }}>
          <Box minWidth={400} maxWidth={680}>
            <TextField
              variant="filled"
              size="small"
              fullWidth
              value={inputValue}
              onChange={handleInputChange}
              label="Search Property"
              placeholder="example: userId"
              {...commonTextFieldProps}
              sx={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                zIndex: 1,
                pr: 2,
              }}
            />
            <Box pr={2}>
              {shouldRenderVirtualTree && (
                <VirtualTree
                  groupedFields={groupedFields}
                  itemSize={50}
                  NodeComponent={(props) => (
                    <VisibleFieldNode {...props} selectedEntity={entityValue} />
                  )}
                  values={visibleFieldsValues}
                  optionsMap={visibleFieldsOptionsMap}
                />
              )}
            </Box>
          </Box>
        </PopoverMenu>
      )}
    </>
  );
}

const VisibleFieldNode = ({
  data: { isLeaf, name, nestingLevel, property },
  style,
  optionsMap,
  values,
  toggleTree,
  treeState,
  selectedEntity,
}) => {
  const parentName = getParentName(nestingLevel, name, property);

  const { handleToggleFieldVisibility, handleToggleAllFieldsVisibility } = reportViewStateHandlers;

  const isChecked =
    values.includes(`${parentName}.${extractPropertyName(property)}`) && property.visible === true;

  return (
    <Stack py={1} mt={1} mr={1} style={style}>
      {isLeaf ? (
        <Stack mt={-1} pl={4.5} direction="row" alignItems="center" justifyContent="space-between">
          <Stack width="70%">
            <Tooltip title={extractPropertyName(property)} arrow>
              <Typography color={property.visible ? 'textPrimary' : 'textSecondary'} noWrap>
                {extractPropertyName(property)}
              </Typography>
            </Tooltip>
            <Typography color="textSecondary" variant="body2" noWrap>
              {property?.label ?? ''}
            </Typography>
          </Stack>
          {property.isKey ? (
            <Tooltip title="Key">
              <Box>
                <MdVpnKey fontSize={16} />
              </Box>
            </Tooltip>
          ) : (
            property.required && (
              <Tooltip title="Required">
                <Typography fontSize={20} fontWeight="bold">
                  *
                </Typography>
              </Tooltip>
            )
          )}

          {property?.visible ? (
            <Switch
              size="small"
              checked={isChecked}
              onChange={handleToggleFieldVisibility}
              disabled={property.isKey || !property.visible}
              color="primary"
              name={`${parentName}.${extractPropertyName(property)}`}
              inputProps={{ 'aria-label': 'checkbox' }}
            />
          ) : (
            <Typography color="textSecondary" fontStyle="italic" variant="body2" noWrap mr={1}>
              not viewable
            </Typography>
          )}
        </Stack>
      ) : (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack
            component={Button}
            onClick={toggleTree(parentName)}
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ textTransform: 'none' }}
            disableRipple
          >
            {treeState[parentName] ? (
              <MdKeyboardArrowDown fontSize={20} />
            ) : (
              <MdChevronRight fontSize={20} />
            )}
            <Typography>
              {parentName === ROOT_LEVEL_GROUP_NAME
                ? selectedEntity?.name ?? 'Base Properties'
                : parentName}
            </Typography>
          </Stack>
          <Switch
            size="small"
            checked={checkIfGroupIsSelected(optionsMap[parentName], values, parentName)}
            onChange={handleToggleAllFieldsVisibility}
            color="primary"
            name={name}
            inputProps={{ 'aria-label': 'checkbox' }}
          />
        </Stack>
      )}
    </Stack>
  );
};

export const extractPropertiesBasedOnParentName = (allFields, parentName) => {
  let fields = [];
  if (parentName === ROOT_LEVEL_GROUP_NAME) {
    fields = allFields.filter((field) => !field.parent);
  } else {
    fields = allFields.filter((field) => field.parent === parentName);
  }

  return fields;
};

const getParentName = (nestingLevel, name, property) => {
  if (nestingLevel === 0) {
    return name;
  } else {
    if (property.parent) {
      return property.parent;
    }

    return ROOT_LEVEL_GROUP_NAME;
  }
};

const checkIfGroupIsSelected = (selectedGroup, selectedFields, parentName) => {
  if (!selectedGroup) {
    return false;
  }

  const visibleFields = pickBy(selectedGroup, ({ visible }) => visible);
  const allPropertyKeys = keys(visibleFields).map((key) => `${parentName}.${key}`);

  return !allPropertyKeys.some((key) => !selectedFields.includes(key));
};
