import { Box, Drawer, Fab, Tab, Tabs, Tooltip } from '@mui/material';
import React, { useMemo, useRef } from 'react';
import { BsReverseLayoutSidebarInsetReverse } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import { MdRefresh } from 'react-icons/md';

import { useQueryAccessGroups, useQueryLogs } from 'data/queriesAndBookmarks';
import { DOM_IDS, LOG_TYPE } from './constants';
import JobsPanel from './JobsPanel';
import BookmarkPanel from './BookmarkPanel';
import ReportViewLogsPanel from './ReportViewLogsPanel';

export default function ReportViewDrawer({
  schema,
  reportViewBookmarks,
  bookmarkError,
  isBookmarkLoading,
  isDrawerOpen,
  toggleDrawer,
  baseEndpoint,
  systemType,
  openCreateBookmarkDialog,
  setQueryData,
  tabIndex,
  updateTabIndex,
  isCreatingJob,
  openDrawer,
  updateDrawerTabIndex,
  connection,
}) {
  const refreshRef = useRef();
  const params = useParams();

  const {
    isRefetching: isLogRefetching,
    isLoading: isLogLoading,
    data: logData,
    error: logError,
    refetch: refetchLogs,
  } = useQueryLogs(params.connectionId);

  const accessGroupsInfo = useQueryAccessGroups();

  const reportViewLogs = useMemo(
    () => logData?.filter((log) => log.type === LOG_TYPE.REPORT_VIEW),
    [logData]
  );

  return (
    <>
      <Tooltip title="Open Drawer">
        <Fab
          size="small"
          sx={(theme) => ({
            width: theme.spacing(4.5),
            height: theme.spacing(4.5),
            borderRadius: theme.spacing(0.5),
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            boxShadow: 'none',
            position: 'fixed',
            zIndex: theme.zIndex.modal - 1,
            right: theme.spacing(2),
            top: theme.spacing(13),
            mr: isDrawerOpen ? 50 : 0,
            ':hover': {
              backgroundColor: theme.palette.primary.dark,
            },
          })}
          onClick={toggleDrawer}
        >
          <BsReverseLayoutSidebarInsetReverse size="18px" />
        </Fab>
      </Tooltip>
      {isDrawerOpen && (
        <Drawer
          anchor="right"
          variant="persistent"
          elevation={0}
          open={isDrawerOpen}
          PaperProps={{ id: DOM_IDS.REPORT_VIEW_DRAWER }}
          sx={(theme) => ({
            '& .MuiDrawer-paper': {
              width: theme.spacing(50),
              overflow: 'hidden',
              position: 'fixed',
              height: 'calc(100% - 44px)',
              top: theme.spacing(5.5),
            },
          })}
        >
          <Tabs
            value={tabIndex}
            indicatorColor="primary"
            onChange={updateTabIndex}
            textColor="primary"
            variant="fullWidth"
            id={DOM_IDS.TABS_PANEL}
          >
            <Tab
              sx={{
                height: '48px !important',
              }}
              label="Bookmarks"
              disableRipple
              id="tab-0"
              aria-controls="tabpanel-0"
            />
            <Tab
              sx={{
                minHeight: '48px !important',
              }}
              label="Query Logs"
              disableRipple
              id="tab-1"
              aria-controls="tabpanel-1"
              {...(tabIndex === 1 && {
                icon: (
                  <Box component="span" mt={0.4} onClick={refetchLogs} size="small">
                    <MdRefresh size={14} />
                  </Box>
                ),
                iconPosition: 'end',
              })}
            />
            <Tab
              sx={{
                minHeight: '48px !important',
              }}
              label="Jobs"
              disableRipple
              id="tab-2"
              aria-controls="tabpanel-2"
              {...(tabIndex === 2 && {
                icon: (
                  <Box component="span" mt={0.4} ref={refreshRef} size="small">
                    <MdRefresh size={14} />
                  </Box>
                ),
                iconPosition: 'end',
              })}
            />
            {/* <Tab
              sx={{
                minHeight: '48px !important',
              }}
              label="Data Edit Logs"
              {...(tabIndex === 1 && {
                icon: (
                  <Box component="span" mt={0.4} size="small">
                    <MdRefresh size={14} />
                  </Box>
                ),
                iconPosition: 'end',
              })}
              disableRipple
              id="tab-1"
              aria-controls="tabpanel-1"
            /> */}
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
            <BookmarkPanel
              data={reportViewBookmarks}
              isLoading={isBookmarkLoading}
              error={bookmarkError}
              schema={schema}
              baseEndpoint={baseEndpoint}
              systemType={systemType}
              setQueryData={setQueryData}
              connection={connection}
              accessGroupsInfo={accessGroupsInfo}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <ReportViewLogsPanel
              logData={reportViewLogs}
              bookmarkData={reportViewBookmarks}
              isBookmarkLoading={isBookmarkLoading}
              isLogRefetching={isLogRefetching}
              isLogLoading={isLogLoading}
              logError={logError}
              baseEndpoint={baseEndpoint}
              bookmarkError={bookmarkError}
              schema={schema}
              systemType={systemType}
              openCreateBookmarkDialog={openCreateBookmarkDialog}
              refetchLogs={refetchLogs}
              setQueryData={setQueryData}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={2}>
            <JobsPanel
              ref={refreshRef}
              isCreatingJob={isCreatingJob}
              openDrawer={openDrawer}
              updateDrawerTabIndex={updateDrawerTabIndex}
              connection={connection}
            />
          </TabPanel>
          {/* <TabPanel value={tabIndex} index={1}>
            <RunLogsPanel ref={refreshRef} />
          </TabPanel> */}
        </Drawer>
      )}
    </>
  );
}

// TODO: Implement virtualization for RunLogs Panel
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ overflowY: 'auto', overflowX: 'hidden' }}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}
